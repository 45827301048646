import React from  "react";
import ExampleDoc from '../SCG.pdf'
import {Button} from 'antd';

import styles from "../Styles";

const Header = () => {
    return (
        <div style={styles.RedLine}>
            <p class="alignleft"  > 
                <h1><img src = "/Images/SCGC Logo_Full Color.png" style={styles.SCGLogo}/></h1>                       
            </p>

            <p class="alignright">                   
                <a href={ExampleDoc} download="SCG Order Tracking User Manual" target='_blank'>
                    <Button size="large" type="primary" style={{ background: "#FFFFFF", borderColor: "#FFFFFF", fontFamily: 'SCGReg', marginTop: 30 ,color: '#313289' }}  >User Manual</Button>
                </a>
            </p>
        </div>
    );
}
export default Header;