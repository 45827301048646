import React, { Component } from "react";
import {
  Row,
  Col,
  Divider,
  Input,
  Select,
  Button,
  Modal,
  Spin,
  Space,
} from "antd";
import styles from "../Styles";
import Header from "./Header";
import Body from "./Body";
import BodyMobile from "./BodyMobile";
import Footer from "./Footer";
import axios from "axios";
import GhostBody from "./GhostBody";
import GhostBodyMobile from "./GhostBodyMobile";

const { format } = require('date-fns')

const { Option } = Select;

function amountQuantityExportBid(item) {
  return item.sumQuantityExportBid;
}

function amountQuantityExportOrder(item) {
  return item.sumQuantityExportOrder;
}

function sum(prev, next) {
  return prev + next;
}

class App extends Component {
  constructor() {
    super();

    this.state = {
      clickFromCollapseButton: false,
      isToggleOn: false,
      statusCollapse: "1",
      loading: false,
      visible2: false,
      searchStatus: false,
      option: null,
      number: null,
      visible: false,
      datas: [],
      datas2: [],
      value: "",
      selectedOption: null,
      searchDropDown: "",
      sumQuantityEO: 0,
      sumQuantityEB: 0,
      countObject: 0,
      arrayRefBidNo: [],
      arrayUniqueRefBidNo: [],
      uniqueRefBidNo: "",
      arrayRefPONo: [],
      arrayUniqueRefPONo: [],
      uniqueRefPONo: "",
      paddingTopStatus: 0,
      isLoading: false,
      validateTrackingNo: false,
      validateSelect: false,
      notFound: false,
      lastestUpdated: '-',
    };

    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onDropdownchange = this.onDropdownchange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.pathname) {
      window.gtag("config", "G-0JQ7KNVYTW", {
        page_title: this.props.location.pathname,
        page_path: this.props.location.pathname,
      });
    }
  }

  handleClick() {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));

    this.setState({ clickFromCollapseButton: true });

    if (this.state.isToggleOn === true) {
      this.setState({ statusCollapse: "1" });
    } else if (this.state.isToggleOn === false) {
      this.setState({ statusCollapse: "0" });
    }

    this.onSubmitForm();
  }

  onDropdownchange(event) {
    this.setState({
      searchDropDown: event,
      visible: false,
    });
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      visible: false,
    });
  }

  onSubmitForm() {
    if (
      this.state.searchDropDown &&
      this.state.searchDropDown.length > 0 &&
      this.state.SearchNo &&
      this.state.SearchNo.length > 0
    ) {
      this.setState({
        isLoading: true,
        visible: false,
        validateSelect: false,
        validateTrackingNo: false,
        notFound: false,
      });

      let lu;
      if (this.state.searchDropDown === "Option1") {
        const url = `${process.env.REACT_APP_API}/Option1/${this.state.SearchNo}`;
        axios
          .get(url)
          .then((res) => {
            this.setState({ isLoading: false });
            if (!Object.keys(res.data).length) {
              this.showModal();
            } else {
              const datas = res.data;
              const lastestUpdated = new Date(datas.reduce((a, b) => {
                return new Date(a.updatedDate) > new Date(b.updatedDate) ? a : b;
              }).updatedDate) || '-';
              if (this.state.clickFromCollapseButton === true) {
                const datas2 = res.data[0];

                this.setState({ datas2 });
                this.setState({ visible: true });
                this.setState({
                  sumQuantityEB: datas.map(amountQuantityExportBid).reduce(sum),
                });
                this.setState({
                  sumQuantityEO: datas
                    .map(amountQuantityExportOrder)
                    .reduce(sum),
                });
                {
                  this.setState({ searchStatus: false });
                }

                {
                  window.innerWidth < 500 ? (
                    <div> {this.setState({ paddingTopStatus: 25 })}</div>
                  ) : (
                    <div> {this.setState({ paddingTopStatus: 50 })}</div>
                  );
                }

                this.setState({ clickFromCollapseButton: false });
              } else {
                const datas2 = res.data[0];

                this.setState({ datas });
                this.setState({ datas2 });
                this.setState({ isToggleOn: false });
                this.setState({ statusCollapse: "1" });
                this.setState({ visible: true });
                this.setState({
                  sumQuantityEB: datas.map(amountQuantityExportBid).reduce(sum),
                });
                this.setState({
                  sumQuantityEO: datas
                    .map(amountQuantityExportOrder)
                    .reduce(sum),
                });

                {
                  this.setState({ searchStatus: false });
                }

                {
                  window.innerWidth < 500 ? (
                    <div> {this.setState({ paddingTopStatus: 25 })}</div>
                  ) : (
                    <div> {this.setState({ paddingTopStatus: 50 })}</div>
                  );
                }
              }
              lu = new Date(lastestUpdated +" GMT+0700").toISOString();
              this.setState({ lastestUpdated: lu });
            }
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            this.showModal();
          });
      } else if (this.state.searchDropDown === "Option2") {
        const url = `${process.env.REACT_APP_API}/Option2/${this.state.SearchNo}`;
        axios
          .get(url)
          .then((res) => {
            this.setState({ isLoading: false });
            if (
              !Object.keys(res.data).length ||
              res.data[0].transactionType === "Z102"
            ) {
              this.showModal();
            } else {
              const datas = res.data;
              const lastestUpdated = new Date(datas.reduce((a, b) => {
                return new Date(a.updatedDate) > new Date(b.updatedDate) ? a : b;
              }).updatedDate) || '-';
              if (this.state.clickFromCollapseButton === true) {
                var tempArrayRefBidNo = [];
                var tempArrayRefPONo = [];

                this.setState({ countObject: res.data.length });
                this.setState({ datas });
                this.setState({ visible: true });
                {
                  this.setState({ searchStatus: false });
                }

                {
                  window.innerWidth < 500 ? (
                    <div> {this.setState({ paddingTopStatus: 25 })}</div>
                  ) : (
                    <div> {this.setState({ paddingTopStatus: 50 })}</div>
                  );
                }

                this.setState({ clickFromCollapseButton: false });
              } else {
                var tempArrayRefBidNo = [];
                var tempArrayRefPONo = [];

                this.setState({ countObject: res.data.length });
                this.setState({ datas });
                this.setState({ isToggleOn: false });
                this.setState({ statusCollapse: "1" });
                this.setState({ visible: true });
                this.setState({ searchStatus: false });

                {
                  window.innerWidth < 500 ? (
                    <div> {this.setState({ paddingTopStatus: 25 })}</div>
                  ) : (
                    <div> {this.setState({ paddingTopStatus: 50 })}</div>
                  );
                }

                <div>
                  {datas.map((item, i) => {
                    tempArrayRefBidNo.push(item.refBidNo);
                    this.setState({ arrayRefBidNo: tempArrayRefBidNo });
                    this.setState({
                      arrayUniqueRefBidNo: [
                        ...new Set(this.state.arrayRefBidNo),
                      ],
                    });
                    this.setState({
                      uniqueRefBidNo: this.state.arrayUniqueRefBidNo.join(", "),
                    });

                    tempArrayRefPONo.push(item.refPONo);
                    this.setState({ arrayRefPONo: tempArrayRefPONo });
                    this.setState({
                      arrayUniqueRefPONo: [...new Set(this.state.arrayRefPONo)],
                    });
                    this.setState({
                      uniqueRefPONo: this.state.arrayUniqueRefPONo.join(", "),
                    });
                  })}
                </div>;
              }
              lu = new Date(lastestUpdated +" GMT+0700").toISOString();
              this.setState({ lastestUpdated: lu });
            }
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            this.showModal();
          });
      } else if (this.state.searchDropDown === "Option3") {
        const url = `${process.env.REACT_APP_API}/Option3/${this.state.SearchNo}`;
        axios
          .get(url)
          .then((res) => {
            this.setState({ isLoading: false });
            if (!Object.keys(res.data).length) {
              this.showModal();
            } else {
              const datas = res.data;
              const lastestUpdated = new Date(datas.reduce((a, b) => {
                return new Date(a.updatedDate) > new Date(b.updatedDate) ? a : b;
              }).updatedDate) || '-';
              if (this.state.clickFromCollapseButton === true) {
                const datas2 = res.data[0];

                this.setState({ datas });
                this.setState({ datas2 });
                this.setState({ visible: true });
                this.setState({
                  sumQuantityEB: datas.map(amountQuantityExportBid).reduce(sum),
                });
                this.setState({
                  sumQuantityEO: datas
                    .map(amountQuantityExportOrder)
                    .reduce(sum),
                });
                {
                  this.setState({ searchStatus: false });
                }

                {
                  window.innerWidth < 500 ? (
                    <div> {this.setState({ paddingTopStatus: 25 })}</div>
                  ) : (
                    <div> {this.setState({ paddingTopStatus: 50 })}</div>
                  );
                }

                this.setState({ clickFromCollapseButton: false });
              } else {
                const datas2 = res.data[0];

                this.setState({ datas });
                this.setState({ datas2 });
                this.setState({ isToggleOn: false });
                this.setState({ statusCollapse: "1" });
                this.setState({ visible: true });
                this.setState({
                  sumQuantityEB: datas.map(amountQuantityExportBid).reduce(sum),
                });
                this.setState({
                  sumQuantityEO: datas
                    .map(amountQuantityExportOrder)
                    .reduce(sum),
                });
                {
                  this.setState({ searchStatus: false });
                }

                {
                  window.innerWidth < 500 ? (
                    <div> {this.setState({ paddingTopStatus: 25 })}</div>
                  ) : (
                    <div> {this.setState({ paddingTopStatus: 50 })}</div>
                  );
                }
              }
              lu = new Date(lastestUpdated +" GMT+0700").toISOString();
              this.setState({ lastestUpdated: lu });
            }
          })
          .catch((err) => {
            this.setState({ isLoading: false });
            this.showModal();
          });
      } else {
        this.showModal();
      }
    } else {
      if (!this.state.searchDropDown || this.state.searchDropDown.length == 0) {
        this.setState({ validateSelect: true });
      }
      if (!this.state.SearchNo || this.state.SearchNo.length == 0) {
        this.setState({ validateTrackingNo: true });
      }
    }
  }

  componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    <div>
      {searchParams.get("option") != null &&
      searchParams.get("number") != null ? (
        <div>
          {this.setState({ searchStatus: true })}
          {this.setState({ searchDropDown: searchParams.get("option") })}
          {this.setState({ SearchNo: searchParams.get("number") })}
        </div>
      ) : null}
    </div>;
  }

  resize() {
    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== this.state.hideNav) {
      this.setState({ hideNav: currentHideNav });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  handleCancel = () => {
    this.setState({ visible2: false });
  };

  showModal = () => {
    this.setState({
      visible2: true,
    });
  };

  render() {
    const { visible2 } = this.state;
    return (
      <div>
        {this.state.searchStatus === true ? (
          <div>
            {this.onSubmitForm()}
            {this.setState({ searchStatus: false })}
          </div>
        ) : (
          <div></div>
        )}

        <Modal
          visible={visible2}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              style={{
                background: "#313289",
                borderColor: "#313289",
                fontFamily: "SCGReg",
              }}
              key="submit"
              onClick={this.handleCancel}
            >
              OK
            </Button>,
          ]}
        >
          <p align="center">
            <img src="/Images/Explanation.png" style={styles.Explantion} />
          </p>
          {this.state.searchDropDown === "Option1" &&
          this.state.SearchNo &&
          this.state.SearchNo.length > 0 ? (
            <h6 align="center" style={{ fontFamily: "SCGReg" }}>
              Proforma Invoice Number doesn’t exist, please try again.{" "}
            </h6>
          ) : this.state.searchDropDown === "Option2" &&
            this.state.SearchNo &&
            this.state.SearchNo.length > 0 ? (
            <h6 align="center" style={{ fontFamily: "SCGReg" }}>
              Sales Order Number doesn’t exist, please try again.{" "}
            </h6>
          ) : this.state.searchDropDown === "Option3" &&
            this.state.SearchNo &&
            this.state.SearchNo.length > 0 ? (
            <h6 align="center" style={{ fontFamily: "SCGReg" }}>
              Purchase Order Number doesn’t exist, please try again.{" "}
            </h6>
          ) : null}
          {this.state.notFound ? "Data Not found" : ""}
        </Modal>

        <Header />
        <Divider />

        <Row justify="center" style={{ paddingBottom: 25 }}>
          <div class="center-align">
            <h2 align="center" style={{ fontFamily: "SCGReg" }}>
              {" "}
              TRACK & TRACE{" "}
            </h2>
            <p align="center" style={{ fontFamily: "SCGReg" }}>
              {" "}
              Enter Proforma Invoice (PI), Purchase Order (PO) ​and Sales Order
              (SO) Number to track your shipments. <br />
            </p>
          </div>
        </Row>

        {window.innerWidth < 550 ? (
          <div>
            <Row justify="center" style={{ paddingBottom: 25 }}>
              <Col span={2}> </Col>
              <Col span={20}>
                <Input.Group compact>
                  <Select
                    defaultValue=""
                    style={{ width: "30%" }}
                    value={this.state.searchDropDown}
                    onChange={this.onDropdownchange}
                    status={this.state.validateSelect ? "error" : ""}
                  >
                    <Option value="">-- Select --</Option>
                    <Option value="Option1">PI No.</Option>
                    <Option value="Option3">PO No.</Option>
                    <Option value="Option2">Order No.</Option>
                  </Select>

                  <Input
                    allowClear
                    style={{ width: "70%" }}
                    placeholder="Enter your tracking number .."
                    name="SearchNo"
                    type="text"
                    status="error"
                    value={this.state.SearchNo}
                    onChange={this.onInputchange}
                  />
                </Input.Group>
              </Col>
              <Col span={2}></Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row justify="center" style={{ paddingBottom: 25 }}>
              <Col span={6}> </Col>
              <Col span={12}>
                <Input.Group compact>
                  <Select
                    defaultValue=""
                    style={{ width: "30%", fontFamily: "SCGReg" }}
                    value={this.state.searchDropDown}
                    onChange={this.onDropdownchange}
                    status={this.state.validateSelect ? "error" : ""}
                  >
                    <Option value="">-- Select --</Option>
                    <Option value="Option1" style={{ fontFamily: "SCGReg" }}>
                      Proforma Invoice Number
                    </Option>
                    <Option value="Option3" style={{ fontFamily: "SCGReg" }}>
                      Purchase Order Number
                    </Option>
                    <Option value="Option2" style={{ fontFamily: "SCGReg" }}>
                      Sales Order Number​
                    </Option>
                  </Select>

                  <Input
                    allowClear
                    style={{ width: "70%", fontFamily: "SCGReg" }}
                    placeholder="Enter your tracking number .."
                    name="SearchNo"
                    type="text"
                    value={this.state.SearchNo}
                    onChange={this.onInputchange}
                    status={this.state.validateTrackingNo ? "error" : ""}
                  />
                </Input.Group>
              </Col>
              <Col span={6}></Col>
            </Row>
          </div>
        )}

        <Row justify="center" style={{ paddingBottom: 0 }}>
          <Button
            size="large"
            type="primary"
            style={{
              background: "#313183",
              borderColor: "#313289",
              fontFamily: "SCGReg",
            }}
            onClick={this.onSubmitForm}
          >
            Search
          </Button>
        </Row>
        {this.state.isLoading ? (
          <Row justify="center" style={{ paddingTop: 10 }}>
            <Col span={24}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Spin tip="Loading">
                  <div className="content" />
                </Spin>
              </Space>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row
          justify="center"
          style={{ paddingTop: this.state.paddingTopStatus }}
        >
          <Col span={24}>
            {this.state.visible === true &&
            this.state.searchDropDown === "Option1" &&
            this.state.sumQuantityEO != 0 ? (
              <div>
                {window.innerWidth < 550 ? (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={22}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#313289",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#313289", fontFamily: "SCGReg" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      {/* <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {format(new Date(this.state.lastestUpdated),'MMM,dd yyyy HH:mm')}
                      </Col> */}
                    </Row>

                    {this.state.datas.map((item, i) => {
                      return (
                        <div key={i}>
                          {item.transactionType === "Z102" &&
                          this.state.sumQuantityEO <
                            this.state.sumQuantityEB ? (
                            <div>
                              <GhostBodyMobile
                                refBidNo={item.refBidNo}
                                refPONo={item.refPONo}
                                statusCollapse={this.state.statusCollapse}
                                paymentTerm={item.paymentTerm}
                                bidApproveDate={item.bidApproveDate}
                                transactionType={item.transactionType}
                              />
                            </div>
                          ) : (
                            <BodyMobile
                              refBidNo={this.state.SearchNo}
                              bookingNo={item.bookingNo}
                              transactionType={item.transactionType}
                              documentNo={item.documentNo}
                              bidApproveDate={item.bidApproveDate}
                              ETADate={item.etadate}
                              loadDatePlan={item.loadDatePlan}
                              GIQuantity={item.GIQuantity}
                              orderQuantity={item.orderQuantity}
                              ETDDate={item.etddate}
                              documentItem={item.documentItem}
                              refPONo={item.refPONo}
                              shipToParty={item.shipToPartyDesc}
                              shipAgent={item.shipAgentDesc}
                              SFItemStatus={item.SFItemStatus}
                              searchDropDown={this.state.searchDropDown}
                              soapproveDate={item.soapproveDate}
                              sumOfGIQuantity={item.sumOfGIQuantity}
                              sumOfQuantity={item.sumOfQuantity}
                              bookingConfirmDate={item.bookingConfirmDate}
                              createDate={item.createdDate}
                              exposureNo={item.exposureNo}
                              paymentTerm={item.paymentTerm}
                              statusCollapse={this.state.statusCollapse}
                              shipAgentUrl={item.shipAgentUrl}
                              bankCourierName={item.bankCourierName}
                              bankCourierDate={item.dispatched}
                              bankCourierN={item.courierNo}
                              bankCourierReceivedDate={item.arrived}
                              blNo={item.blnumber}
                              courierCompany={item.courierCompany}                              
                              recordDate={item.recordDate}
                            />
                          )}
                          <Row justify="center" style={{ paddingTop: 50 }}>
                            {" "}
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={8}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#313289",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#313289", fontFamily: "SCGReg" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {this.state.lastestUpdated !== '-' ? format(new Date(this.state.lastestUpdated),`MMM,dd yyyy HH:mm a z`): '-'}
                      </Col>
                    </Row>

                    {this.state.datas.map((item, i) => {
                      return (
                        <div key={i}>
                          {item.transactionType === "Z102" &&
                          this.state.sumQuantityEO <
                            this.state.sumQuantityEB ? (
                            <div>
                              <GhostBody
                                refBidNo={item.refBidNo}
                                refPONo={item.refPONo}
                                statusCollapse={this.state.statusCollapse}
                                paymentTerm={item.paymentTerm}
                                bidApproveDate={item.bidApproveDate}
                                transactionType={item.transactionType}
                              />
                            </div>
                          ) : (
                            <Body
                              refBidNo={this.state.SearchNo}
                              bookingNo={item.bookingNo}
                              transactionType={item.transactionType}
                              documentNo={item.documentNo}
                              bidApproveDate={item.bidApproveDate}
                              ETADate={item.etadate}
                              loadDatePlan={item.loadDatePlan}
                              GIQuantity={item.GIQuantity}
                              orderQuantity={item.orderQuantity}
                              ETDDate={item.etddate}
                              documentItem={item.documentItem}
                              refPONo={item.refPONo}
                              shipToParty={item.shipToPartyDesc}
                              shipAgent={item.shipAgentDesc}
                              SFItemStatus={item.SFItemStatus}
                              searchDropDown={this.state.searchDropDown}
                              soapproveDate={item.soapproveDate}
                              sumOfGIQuantity={item.sumOfGIQuantity}
                              sumOfQuantity={item.sumOfQuantity}
                              bookingConfirmDate={item.bookingConfirmDate}
                              createDate={item.createdDate}
                              exposureNo={item.exposureNo}
                              paymentTerm={item.paymentTerm}
                              statusCollapse={this.state.statusCollapse}
                              shipAgentUrl={item.shipAgentUrl}
                              bankCourierName={item.bankCourierName}
                              bankCourierDate={item.dispatched}
                              bankCourierN={item.courierNo}
                              bankCourierReceivedDate={item.arrived}
                              blNo={item.blnumber}
                              courierCompany={item.courierCompany}
                              recordDate={item.recordDate}
                            />
                          )}
                          <Row justify="center" style={{ paddingTop: 50 }}>
                            {" "}
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : this.state.visible === true &&
              this.state.searchDropDown === "Option2" ? (
              <div>
                {window.innerWidth < 550 ? (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={22}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#313289",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#313289", fontFamily: "SCGReg" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      {/* <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {format(new Date(this.state.lastestUpdated),'MMM,dd yyyy HH:mm')}
                      </Col> */}
                    </Row>

                    <BodyMobile
                      refBidNo={this.state.datas[0].refBidNo}
                      bookingNo={this.state.datas[0].bookingNo}
                      transactionType={this.state.datas[0].transactionType}
                      documentNo={this.state.SearchNo}
                      bidApproveDate={this.state.datas[0].bidApproveDate}
                      ETADate={this.state.datas[0].etadate}
                      loadDatePlan={this.state.datas[0].loadDatePlan}
                      GIQuantity={this.state.datas[0].GIQuantity}
                      orderQuantity={this.state.datas[0].orderQuantity}
                      ETDDate={this.state.datas[0].etddate}
                      documentItem={this.state.datas[0].documentItem}
                      refPONo={this.state.datas[0].refPONo}
                      shipToParty={this.state.datas[0].shipToPartyDesc}
                      shipAgent={this.state.datas[0].shipAgentDesc}
                      SFItemStatus={this.state.datas[0].SFItemStatus}
                      countObject={this.state.countObject}
                      datas={this.state.datas}
                      uniqueRefBidNo={this.state.uniqueRefBidNo}
                      uniqueRefPONo={this.state.uniqueRefPONo}
                      searchDropDown={this.state.searchDropDown}
                      soapproveDate={this.state.datas[0].soapproveDate}
                      bookingConfirmDate={
                        this.state.datas[0].bookingConfirmDate
                      }
                      createDate={this.state.datas[0].createdDate}
                      exposureNo={this.state.datas[0].exposureNo}
                      paymentTerm={this.state.datas[0].paymentTerm}
                      statusCollapse={this.state.statusCollapse}
                      shipAgentUrl={this.state.datas[0].shipAgentUrl}
                      bankCourierName={this.state.datas[0].bankCourierName}
                      bankCourierDate={this.state.datas[0].dispatched}
                      bankCourierN={this.state.datas[0].courierNo}
                      bankCourierReceivedDate={this.state.datas[0].arrived}
                      blNo={this.state.datas[0].blnumber}
                      courierCompany={this.state.datas[0].courierCompany}                      
                      recordDate={this.state.datas[0].recordDate}
                    />
                    <Row justify="center" style={{ paddingTop: 50 }}>
                      {" "}
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={8}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#BC0000",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#313289", fontFamily: "SCGReg" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {this.state.lastestUpdated !== '-' ? format(new Date(this.state.lastestUpdated),`MMM,dd yyyy HH:mm a z`): '-'}
                      </Col>
                    </Row>

                    <Body
                      refBidNo={this.state.datas[0].refBidNo}
                      bookingNo={this.state.datas[0].bookingNo}
                      transactionType={this.state.datas[0].transactionType}
                      documentNo={this.state.SearchNo}
                      bidApproveDate={this.state.datas[0].bidApproveDate}
                      ETADate={this.state.datas[0].etadate}
                      loadDatePlan={this.state.datas[0].loadDatePlan}
                      GIQuantity={this.state.datas[0].GIQuantity}
                      orderQuantity={this.state.datas[0].orderQuantity}
                      ETDDate={this.state.datas[0].etddate}
                      documentItem={this.state.datas[0].documentItem}
                      refPONo={this.state.datas[0].refPONo}
                      shipToParty={this.state.datas[0].shipToPartyDesc}
                      shipAgent={this.state.datas[0].shipAgentDesc}
                      SFItemStatus={this.state.datas[0].SFItemStatus}
                      countObject={this.state.countObject}
                      datas={this.state.datas}
                      uniqueRefBidNo={this.state.uniqueRefBidNo}
                      uniqueRefPONo={this.state.uniqueRefPONo}
                      searchDropDown={this.state.searchDropDown}
                      soapproveDate={this.state.datas[0].soapproveDate}
                      bookingConfirmDate={
                        this.state.datas[0].bookingConfirmDate
                      }
                      createDate={this.state.datas[0].createdDate}
                      exposureNo={this.state.datas[0].exposureNo}
                      paymentTerm={this.state.datas[0].paymentTerm}
                      statusCollapse={this.state.statusCollapse}
                      shipAgentUrl={this.state.datas[0].shipAgentUrl}
                      bankCourierName={this.state.datas[0].bankCourierName}
                      bankCourierDate={this.state.datas[0].dispatched}
                      bankCourierN={this.state.datas[0].courierNo}
                      bankCourierReceivedDate={this.state.datas[0].arrived}
                      blNo={this.state.datas[0].blnumber}
                      courierCompany={this.state.datas[0].courierCompany}                      
                      recordDate={this.state.datas[0].recordDate}
                    />
                    <Row justify="center" style={{ paddingTop: 50 }}>
                      {" "}
                    </Row>
                  </div>
                )}
              </div>
            ) : this.state.visible === true &&
              this.state.searchDropDown === "Option3" &&
              this.state.sumQuantityEO != 0 ? (
              <div>
                {window.innerWidth < 550 ? (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={22}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#BC0000",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#313289", fontFamily: "SCGReg" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      {/* <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {format(new Date(this.state.lastestUpdated),'MMM,dd yyyy HH:mm')}
                      </Col> */}
                    </Row>

                    {this.state.datas.map((item, i) => {
                      return (
                        <div key={i}>
                          {item.transactionType === "Z102" &&
                          this.state.sumQuantityEO <
                            this.state.sumQuantityEB ? (
                            <div>
                              <GhostBodyMobile
                                refBidNo={item.refBidNo}
                                refPONo={item.refPONo}
                                statusCollapse={this.state.statusCollapse}
                                paymentTerm={item.paymentTerm}
                                bidApproveDate={item.bidApproveDate}
                                transactionType={item.transactionType}
                              />
                            </div>
                          ) : (
                            <BodyMobile
                              refBidNo={item.refBidNo}
                              bookingNo={item.bookingNo}
                              transactionType={item.transactionType}
                              documentNo={item.documentNo}
                              bidApproveDate={item.bidApproveDate}
                              ETADate={item.etadate}
                              loadDatePlan={item.loadDatePlan}
                              GIQuantity={item.GIQuantity}
                              orderQuantity={item.orderQuantity}
                              ETDDate={item.etddate}
                              documentItem={item.documentItem}
                              refPONo={this.state.SearchNo}
                              shipToParty={item.shipToPartyDesc}
                              shipAgent={item.shipAgentDesc}
                              SFItemStatus={item.SFItemStatus}
                              searchDropDown={this.state.searchDropDown}
                              soapproveDate={item.soapproveDate}
                              sumOfGIQuantity={item.sumOfGIQuantity}
                              sumOfQuantity={item.sumOfQuantity}
                              bookingConfirmDate={item.bookingConfirmDate}
                              createDate={item.createdDate}
                              exposureNo={item.exposureNo}
                              paymentTerm={item.paymentTerm}
                              statusCollapse={this.state.statusCollapse}
                              shipAgentUrl={item.shipAgentUrl}
                              bankCourierName={item.bankCourierName}
                              bankCourierDate={item.dispatched}
                              bankCourierN={item.courierNo}
                              bankCourierReceivedDate={item.arrived}
                              blNo={item.blnumber}
                              courierCompany={item.courierCompany}                              
                              recordDate={item.recordDate}
                            />
                          )}
                          <Row justify="center" style={{ paddingTop: 50 }}>
                            {" "}
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={8}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#BC0000",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#313289", fontFamily: "SCGReg" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {this.state.lastestUpdated !== '-' ? format(new Date(this.state.lastestUpdated),`MMM,dd yyyy HH:mm a z`): '-'}
                      </Col>
                    </Row>

                    {this.state.datas.map((item, i) => {
                      return (
                        <div key={i}>
                          {item.transactionType === "Z102" &&
                          this.state.sumQuantityEO <
                            this.state.sumQuantityEB ? (
                            <div>
                              <GhostBody
                                refBidNo={item.refBidNo}
                                refPONo={item.refPONo}
                                statusCollapse={this.state.statusCollapse}
                                paymentTerm={item.paymentTerm}
                                bidApproveDate={item.bidApproveDate}
                                transactionType={item.transactionType}
                              />
                            </div>
                          ) : (
                            <Body
                              refBidNo={item.refBidNo}
                              bookingNo={item.bookingNo}
                              transactionType={item.transactionType}
                              documentNo={item.documentNo}
                              bidApproveDate={item.bidApproveDate}
                              ETADate={item.etadate}
                              loadDatePlan={item.loadDatePlan}
                              GIQuantity={item.GIQuantity}
                              orderQuantity={item.orderQuantity}
                              ETDDate={item.etddate}
                              documentItem={item.documentItem}
                              refPONo={this.state.SearchNo}
                              shipToParty={item.shipToPartyDesc}
                              shipAgent={item.shipAgentDesc}
                              SFItemStatus={item.SFItemStatus}
                              searchDropDown={this.state.searchDropDown}
                              soapproveDate={item.soapproveDate}
                              sumOfGIQuantity={item.sumOfGIQuantity}
                              sumOfQuantity={item.sumOfQuantity}
                              bookingConfirmDate={item.bookingConfirmDate}
                              createDate={item.createdDate}
                              exposureNo={item.exposureNo}
                              paymentTerm={item.paymentTerm}
                              statusCollapse={this.state.statusCollapse}
                              shipAgentUrl={item.shipAgentUrl}
                              bankCourierName={item.bankCourierName}
                              bankCourierDate={item.dispatched}
                              bankCourierN={item.courierNo}
                              bankCourierReceivedDate={item.arrived}
                              blNo={item.blnumber}
                              courierCompany={item.courierCompany}                              
                              recordDate={item.recordDate}
                            />
                          )}
                          <Row justify="center" style={{ paddingTop: 50 }}>
                            {" "}
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}
          </Col>
          <Col span={24}>
            {this.state.visible === true &&
            this.state.sumQuantityEO < this.state.sumQuantityEB &&
            this.state.searchDropDown !== "Option2" &&
            this.state.sumQuantityEO === 0 ? (
              <div>
                {window.innerWidth < 550 ? (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={22}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#BC0000",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#313289" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      {/* <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {format(new Date(this.state.lastestUpdated),'MMM,dd yyyy HH:mm')}
                      </Col> */}
                    </Row>

                    {this.state.datas.map((item, i) => {
                      return (
                        <div key={i}>
                          <GhostBodyMobile
                            refBidNo={item.refBidNo}
                            refPONo={item.refPONo}
                            statusCollapse={this.state.statusCollapse}
                            paymentTerm={item.paymentTerm}
                            bidApproveDate={item.bidApproveDate}
                            transactionType={item.transactionType}
                            shipToParty={item.shipToPartyDesc}
                          />
                          <Row justify="center" style={{ paddingTop: 50 }}>
                            {" "}
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <Row justify="center" style={{ paddingBottom: 10 }}>
                      <Col span={8}>
                        <Button
                          size="large"
                          type="primary"
                          style={{
                            background: "#ffffff",
                            borderColor: "#BC0000",
                          }}
                          onClick={this.handleClick}
                        >
                          <p style={{ color: "#BC0000" }}>
                            {this.state.isToggleOn ? "Show All" : "Hide All"}
                          </p>
                        </Button>
                      </Col>
                      <Col span={8} style={{ textAlign: 'right', paddingTop: '10px'}}>
                        Lastest Updated: {this.state.lastestUpdated !== '-' ? format(new Date(this.state.lastestUpdated),`MMM,dd yyyy HH:mm a z`): '-'}
                      </Col>
                    </Row>

                    {this.state.datas.map((item, i) => {
                      return (
                        <div key={i}>
                          <GhostBody
                            refBidNo={item.refBidNo}
                            refPONo={item.refPONo}
                            statusCollapse={this.state.statusCollapse}
                            paymentTerm={item.paymentTerm}
                            bidApproveDate={item.bidApproveDate}
                            transactionType={item.transactionType}
                            shipToParty={item.shipToPartyDesc}
                          />{" "}
                          <Row justify="center" style={{ paddingTop: 50 }}>
                            {" "}
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}
          </Col>

          <Row justify="center" style={{ paddingTop: 100 }}></Row>
        </Row>
        <Footer />
      </div>
    );
  }
}
export default App;
