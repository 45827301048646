const ColumnHeader = {
  backgroundColor: 'red',
  padding: 10,
  color: 'white',
  fontSize: '22px',
  fontWeight: 'bold'
}

const ColumnTitle = {
  marginBottom: 15,
  paddingLeft: 10,
  color: 'black',
  fontSize: '14px'
}

const ColumnTitleBold = {
  marginBottom: 8,
  paddingLeft: 10,
  color: 'black',
  fontSize: '14px',
  fontWeight: 'bold'
}

const ColumnTitleBoldRed = {
  marginBottom: 8,
  paddingLeft: 10,
  color: 'red',
  fontSize: '16px',
  fontWeight: 'bold'
}

const ColumnTitleBoldWhite = {
  marginTop: 8,
  marginBottom: 8,
  paddingLeft: 10,
  color: 'white',
  fontSize: '16px',
  fontWeight: 'bold'
}

const IconBoldWhite = {
  marginTop: 4,
  marginBottom: 10,
  paddingLeft: 10,
  color: 'white',
  fontSize: '18px',
  fontWeight: 'bold'
}

const ColumnTitleWhite = {
  marginTop: 8,
  marginBottom: 8,
  paddingLeft: 10,
  color: 'white',
  fontSize: '16px'
}

const TimlineTitle = {
  paddingLeft: 10,
  paddingBottom: 50,
  color: 'black',
  fontSize: '14px'

}

const SCGLogo = {
  height: 50,
  width: 150,
  marginLeft: 20,
  marginTop: 20
}

const Explantion = {
  height: 80,
  width: 80
}

const RedLine = {
  borderTop: '10px solid #009989'
}

const ColumnGray = {
  marginBottom: 8,
  paddingLeft: 10,
  color: '#8c8c8c',
  fontSize: '10px'
}

export default {
  ColumnHeader: ColumnHeader,
  ColumnTitle: ColumnTitle,
  ColumnTitleWhite: ColumnTitleWhite,
  ColumnTitleBold: ColumnTitleBold,
  ColumnTitleBoldRed: ColumnTitleBoldRed,
  ColumnTitleBoldWhite: ColumnTitleBoldWhite,
  TimlineTitle: TimlineTitle,
  SCGLogo: SCGLogo,
  RedLine: RedLine,
  Explantion: Explantion,
  IconBoldWhite: IconBoldWhite,
  ColumnGray: ColumnGray
}
