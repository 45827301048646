import React from  "react";
import { format } from 'date-fns';

export function Calculate(props) {

    const {
        bidApproveDate,
        bookingNo,
        ETADate,
        loadDatePlan,
        ETDDate,
        sumOfGIQuantity,
        sumOfQuantity,
        exposureNo,
        paymentTerm
    } = props;
  
    const statusTimelineArray = []
   
    if( bidApproveDate <= format(new Date(), 'yyyy-MM-dd')){
        statusTimelineArray.push('1');
    }  
    if((paymentTerm === "TS00" || paymentTerm === "TP00" || paymentTerm === "NT00" || paymentTerm.startsWith("LT")) && (exposureNo != null || exposureNo != '')){
        statusTimelineArray.push('2');
    }
    if(paymentTerm != "TS00" && paymentTerm != "TP00" && paymentTerm != "NT00" && !paymentTerm.startsWith("LT")){
        statusTimelineArray.push('2');
    }
    if(bookingNo != null &&  ETADate!= null){
        statusTimelineArray.push('3');

    }if(loadDatePlan <= format(new Date(), 'yyyy-MM-dd') && (sumOfGIQuantity === sumOfQuantity)){
        statusTimelineArray.push('4');

    }if(ETDDate < format(new Date(), 'yyyy-MM-dd')){
        statusTimelineArray.push('5');

    }if(ETADate < format(new Date(), 'yyyy-MM-dd')){
        statusTimelineArray.push('6');
    }

    if(statusTimelineArray.indexOf("6") > -1){
        statusTimelineArray.push('Delivered');
        <div> Delivered </div>

    }else if(statusTimelineArray.indexOf("5") > -1){
        statusTimelineArray.push('Delivering');
        <div> Delivering </div>

    }else if(statusTimelineArray.indexOf("4") > -1){
        statusTimelineArray.push('Cargo Loaded');
        <div> Cargo Loaded </div>
       
    }else if(statusTimelineArray.indexOf("3") > -1){
        statusTimelineArray.push('Booking Confirmed');
        <div> Booking Confirmed </div>
      
    }else if(statusTimelineArray.indexOf("2") > -1){
        statusTimelineArray.push('Waiting Booking Confirmation');
        <div> Waiting Booking Confirmation </div>
      
    }else if(statusTimelineArray.indexOf("1") > -1){
        statusTimelineArray.push('Waiting Payment');
        <div> Waiting Payment </div>
     
    }else{
        <div> - </div>
    }

    return(statusTimelineArray)
}

