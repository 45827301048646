import React, { Component } from 'react'
import { Row, Col, Timeline, Collapse } from 'antd'
import { ClockCircleOutlined, CodepenOutlined, PlusOutlined, CheckCircleFilled, MinusOutlined } from '@ant-design/icons'
import { Calculate } from '../Cal'

import '../App.css'
import styles from '../Styles'
import parse from 'html-react-parser'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'

const { Panel } = Collapse
const { format } = require('date-fns')

class Body extends Component {
  callback(key) {
    console.log('key', key)
  }

  render() {
    const {
      documentNo,
      refBidNo,
      refPONo,
      bidApproveDate,
      bookingNo,
      ETADate,
      loadDatePlan,
      ETDDate,
      shipAgent,
      shipToParty,
      uniqueRefBidNo,
      uniqueRefPONo,
      searchDropDown,
      soapproveDate,
      transactionType,
      bookingConfirmDate,
      createDate,
      statusCollapse,
      shipAgentUrl,
      bankCourierName,
      bankCourierDate,
      bankCourierN,
      bankCourierReceivedDate,
      blNo,
      courierCompany,
      paymentTerm,
      recordDate
    } = this.props

    var resultStatusTimeline = Calculate(this.props)
    var countResultStatusTimeline = [resultStatusTimeline.length - 1]

    const date = new Date(bidApproveDate)
    var paymentDate = 'paymentDate ' + ('0' + (date.getDate() + 15)).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear()

    const formatDateETADate = new Date(ETADate)
    const formatDateETDDate = new Date(ETDDate)
    const formatDateBidApproveDate = new Date(bidApproveDate)
    const formatDateLoadDatePlan = new Date(loadDatePlan)
    const formatDateBookingConfirmDate = new Date(bookingConfirmDate)
    const formatcreateDate = new Date(createDate)

    return (
      <div>
        {(transactionType != 'Z102') ? (
          <Row justify='center'>

            <Col span={16}>
              <Collapse expandIconPosition='right' defaultActiveKey={statusCollapse} onChange={this.callback} expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}>
                <Panel style={{ fontFamily: 'SCGReg' }} header={<div>
                  SO Number: {documentNo}
                  {/* <div className="additional-text">Update:{format(new Date(recordDate),'MMM,dd yyyy HH:mm') }</div> */}
                </div>} key='1'>
                  <div>
                    <Row>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> PI Number: </p>
                          </Col>
                          <Col span={12}>
                            {(searchDropDown === 'Option2') ? (
                              <p style={styles.ColumnTitle} class='ow-break-word'> {uniqueRefBidNo || '-'} </p>
                            ) : (
                              <p style={styles.ColumnTitle} class='ow-break-word'> {refBidNo || '-'} </p>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> PO Number: </p>
                          </Col>
                          <Col span={12}>
                            {(searchDropDown === 'Option2') ? (
                              <p style={styles.ColumnTitle}> {uniqueRefPONo || '-'} </p>
                            ) : (
                              <p style={styles.ColumnTitle}> {refPONo || '-'} </p>
                            )}
                          </Col>
                        </Row>
                      </Col>

                    </Row>

                    <Row>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> SO Number: </p>
                          </Col>
                          <Col span={12}>
                            <p style={styles.ColumnTitleBold} class='ow-break-word'> {documentNo || '-'} </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row>
                          <Col span={12}><p /></Col>
                          <Col span={12}><p /></Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> Booking Number: </p>
                          </Col>
                          <Col span={12}>
                            {(bookingNo === 'TRUCK') ? (
                              <p style={styles.ColumnTitle} class='ow-break-word'> - </p>
                            ) : (
                              <p style={styles.ColumnTitle} class='ow-break-word'> {bookingNo || '-'} </p>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> BL Number: </p>
                          </Col>
                          <Col span={12}>
                            <p style={styles.ColumnTitle} class='ow-break-word'> {blNo || '-'} </p>
                          </Col>
                        </Row>
                      </Col>

                    </Row>

                    <Row>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> ETD: </p>
                          </Col>
                          <Col span={12}>
                            {(resultStatusTimeline.indexOf('3') > -1) ? (
                              <p style={styles.ColumnTitle} class='ow-break-word'> {ETDDate ? `${format(formatDateETDDate, 'PP')}` : '-'} </p>
                            ) : (
                              <p style={styles.ColumnTitle}> - </p>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> ETA: </p>
                          </Col>
                          <Col span={12}>
                            {(resultStatusTimeline.indexOf('3') > -1) ? (
                              <p style={styles.ColumnTitle}> {ETADate ? `${format(formatDateETADate, 'PP')}` : '-'} </p>
                            ) : (
                              <p style={styles.ColumnTitle}> - </p>
                            )}
                          </Col>
                        </Row>
                      </Col>

                    </Row>

                    <Row>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> Ship Agent: </p>
                          </Col>
                          <Col span={12}>
                            {(resultStatusTimeline.indexOf('3') > -1) ? (
                              <p style={styles.ColumnTitle} class='ow-break-word'> {shipAgent || '-'} </p>
                            ) : (
                              <p style={styles.ColumnTitle}> - </p>
                            )}
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> Ship To: </p>
                          </Col>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> {shipToParty || '-'} </p>
                          </Col>
                        </Row>
                      </Col>

                    </Row>

                    <Row>

                      <Col span={6}>
                        <p style={styles.ColumnTitle}> Shipping Agent Address: </p>
                      </Col>
                      <Col span={6}>
                        {(resultStatusTimeline.indexOf('3') > -1) ? (
                          <p style={styles.ColumnTitle}>
                            {(shipAgentUrl === null || shipAgentUrl === '' || shipAgentUrl === '-') ? (
                              <p>-</p>
                            ) : (
                              <p class='ow-break-word'>
                                {(shipAgentUrl.startsWith('http')) ? (
                                  <a href={shipAgentUrl} rel='noreferrer' target='_blank'>
                                    {shipAgentUrl}
                                  </a>
                                ) : (
                                  <a href={'http://' + shipAgentUrl} rel='noreferrer' target='_blank'>
                                    {shipAgentUrl}
                                  </a>
                                )}
                              </p>
                            )}
                          </p>
                        ) : (
                          <p style={styles.ColumnTitle}>-</p>
                        )}
                      </Col>

                    </Row>

                    <Row>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> Courier No.: </p>
                          </Col>
                          <Col span={12}>
                            {(resultStatusTimeline.indexOf('3') > -1) ? (
                              <p style={styles.ColumnTitle} class='ow-break-word'> {bankCourierN || '-'} </p>
                            ) : (
                              <p style={styles.ColumnTitle}> - </p>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle} class='ow-break-word'> Courier Company: </p>
                          </Col>
                          <Col span={12}>
                            {
                              (courierCompany)
                                ? <p style={styles.ColumnTitle} class='ow-break-word'>{parse(courierCompany)}</p>

                                : <p style={styles.ColumnTitle}> - </p>
                            }
                          </Col>
                        </Row>
                      </Col>

                    </Row>

                    <Row>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> Dispatched: </p>
                          </Col>
                          <Col span={12}>
                            <p style={styles.ColumnTitle} class='ow-break-word'> {bankCourierDate || '-'} </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> Arrived: </p>
                          </Col>
                          <Col span={12}>
                            {(resultStatusTimeline.indexOf('3') > -1) ? (
                              <p style={styles.ColumnTitle}> {bankCourierReceivedDate || '-'} </p>
                            ) : (
                              <p style={styles.ColumnTitle}> - </p>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <p style={styles.ColumnGray}>
                        Please use B/L Number or Container Number in Bill of Lading for further tracking. (LCL Shipment may be unable to track in Shipping Agents' Website due to system limitation.)

                      </p>
                    </Row>

                    <Row>
                      <Col span={24} style={{ backgroundColor: '#939598' }}>
                        <p class='alignleft'>

                          <span style={styles.ColumnTitleBoldWhite}> <CodepenOutlined /> Shipping Summary </span>
                        </p>
                        <p class='alignright'>
                          <span style={styles.ColumnTitleWhite}>Order Status: </span>
                          <span style={styles.ColumnTitleBoldWhite}>{resultStatusTimeline[countResultStatusTimeline]}</span>
                        </p>
                      </Col>
                    </Row>

                    <Row justify='center'>
                      <Col span={24}>
                        <Timeline mode='left' style={{ paddingTop: 50 }}>

                          <Timeline.Item
                            label={(bidApproveDate === null) ? (
                              <p />
                            ) : (
                              `${format(formatDateBidApproveDate, 'PP')}`
                            )}

                            style={styles.TimlineTitle} dot={(resultStatusTimeline.indexOf('1') > -1 || resultStatusTimeline.indexOf('2') > -1 || resultStatusTimeline.indexOf('3') > -1 || resultStatusTimeline.indexOf('4') > -1 || resultStatusTimeline.indexOf('5') > -1 || resultStatusTimeline.indexOf('6') > -1) ? (
                              <CheckCircleFilled style={{ fontSize: 30, color: '#5DE5A4' }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            )}
                          >
                            {(resultStatusTimeline.indexOf('2') > -1 || transactionType === 'Z401') ? (
                              <div>
                                <p>Order Confirmed</p>
                                <h10 style={{ color: 'red' }}>​</h10>
                              </div>

                            ) : (
                              <div>
                                <p>Order Confirmed​</p>
                                <h10 style={{ color: 'red' }}>Please remit payment/send Original LC at least 15 Days before requested ETD.​</h10>
                              </div>
                            )}

                          </Timeline.Item>

                          <Timeline.Item
                            label={(soapproveDate === null || createDate === null) ? (
                              <p>​​</p>
                            ) : (
                              `${format(formatcreateDate, 'PP')}`
                            )}

                            style={styles.TimlineTitle} dot={(resultStatusTimeline.indexOf('2') > -1 || resultStatusTimeline.indexOf('3') > -1 || resultStatusTimeline.indexOf('4') > -1 || resultStatusTimeline.indexOf('5') > -1 || resultStatusTimeline.indexOf('6') > -1) ? (
                              <CheckCircleFilled style={{ fontSize: 30, color: '#5DE5A4' }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            )}
                          >
                            <p>Payment/Credit Accepted​​</p>
                            <h10 style={{ color: 'red' }}>​</h10>
                          </Timeline.Item>

                          <Timeline.Item
                            label={(bookingNo === 'TRUCK' || bookingConfirmDate === null || resultStatusTimeline.indexOf('3') <= -1) ? (
                              <p>​​</p>
                            ) : (
                              `${format(formatDateBookingConfirmDate, 'PP')}`
                            )}

                            style={styles.TimlineTitle} dot={((resultStatusTimeline.indexOf('3') > -1 && bookingNo != 'TRUCK' && bookingConfirmDate != null) || resultStatusTimeline.indexOf('4') > -1 || resultStatusTimeline.indexOf('5') > -1 || resultStatusTimeline.indexOf('6') > -1) ? (
                              <CheckCircleFilled style={{ fontSize: 30, color: '#5DE5A4' }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            )}
                          >
                            <p>Booking Confirmed</p>
                            <h10 style={{ color: 'red' }}>Additional charges may apply for any change.​</h10>
                          </Timeline.Item>

                          <Timeline.Item
                            label={(loadDatePlan === null || resultStatusTimeline.indexOf('3') <= -1) ? (
                              <p>​​</p>
                            ) : (
                              `${format(formatDateLoadDatePlan, 'PP')}`
                            )}

                            style={styles.TimlineTitle} dot={((resultStatusTimeline.indexOf('4') > -1 || resultStatusTimeline.indexOf('5') > -1 || resultStatusTimeline.indexOf('6') > -1) && resultStatusTimeline.indexOf('3') > -1) ? (
                              <CheckCircleFilled style={{ fontSize: 30, color: '#5DE5A4' }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            )}
                          >
                            <p>Cargo Loaded​</p>
                            <h10 style={{ color: 'red' }}>​</h10>
                          </Timeline.Item>

                          <Timeline.Item
                            label={(ETDDate === null || resultStatusTimeline.indexOf('3') <= -1) ? (
                              <p>​​</p>
                            ) : (
                              `${format(formatDateETDDate, 'PP')}`
                            )}

                            style={styles.TimlineTitle} dot={((resultStatusTimeline.indexOf('5') > -1 || resultStatusTimeline.indexOf('6') > -1) && resultStatusTimeline.indexOf('3') > -1) ? (
                              <CheckCircleFilled style={{ fontSize: 30, color: '#5DE5A4' }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            )}
                          >
                            <p>Estimated Time Departure</p>
                            <h10 style={{ color: 'red' }}>
                              Subject to change by Ship Agent without prior notice

                            </h10>
                          </Timeline.Item>

                          <Timeline.Item
                            label={(ETADate === null || resultStatusTimeline.indexOf('3') <= -1) ? (
                              <p>​​</p>
                            ) : (
                              `${format(formatDateETADate, 'PP')}`
                            )}

                            style={styles.TimlineTitle} dot={(resultStatusTimeline.indexOf('6') > -1 && resultStatusTimeline.indexOf('3') > -1) ? (
                              <CheckCircleFilled style={{ fontSize: 30, color: '#5DE5A4' }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            )}
                          >
                            <p>Estimated Time Arrival​</p>
                            <h10 style={{ color: 'red' }}>
                              Subject to change by Ship Agent without prior notice
                            </h10>
                          </Timeline.Item>

                        </Timeline>
                      </Col>
                    </Row>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        ) : (null)
        }
      </div>
    )
  }
}
export default Body
