import React, { Component } from "react";
import { Row, Col, Timeline, Collapse } from "antd";
import {
  ClockCircleOutlined,
  CodepenOutlined,
  PlusOutlined,
  CheckCircleFilled,
  MinusOutlined,
} from "@ant-design/icons";

import { Calculate } from "../Cal";

import "../App.css";
import styles from "../Styles";

const { Panel } = Collapse;

class GhostBodyMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusColour: "black",
      statusIcon: 0,
    };
  }

  callback(key) {
    console.log(key);
  }

  render() {
    const {
      documentNo,
      refBidNo,
      refPONo,
      transactionType,
      statusCollapse,
      paymentTerm,
      shipToParty,
    } = this.props;

    return (
      <div>
        {transactionType === "Z102" ? (
          <Row justify="center">
            <Col span={22}>
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={statusCollapse}
                onChange={this.callback}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
              >
                <Panel
                  style={{ fontFamily: "SCGReg" }}
                  header={"Pending Shipment: " + documentNo}
                  key="1"
                >
                  <div>
                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> PI Number: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}>
                          {" "}
                          {!!refBidNo ? refBidNo : "-"}{" "}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> PO Number: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}>
                          {" "}
                          {!!refPONo ? refPONo : "-"}{" "}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Order Number: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> - </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Booking Number: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> - </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}>
                          {" "}
                          Shipping Agent Address:{" "}
                        </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> - </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> ETA: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> - </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Ship To: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> {shipToParty} </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> ETD: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> - </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Ship Agent: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> - </p>
                      </Col>
                    </Row>

                    <Row>
                      <p style={styles.ColumnTitle}>
                        May require Container Number in Bill of Lading for
                        further tracking. (LCL Shipment may be unable to track
                        in Shipping Agents' Website due to system limitation.){" "}
                      </p>
                    </Row>

                    <Row>
                      <Col span={24} style={{ backgroundColor: "#939598" }}>
                        <p style={styles.ColumnTitleBoldWhite}>
                          <CodepenOutlined /> Shipping Summary : Order Status:{" "}
                          <span style={styles.ColumnTitleBoldWhite}>
                            Waiting Payment/Instruction
                          </span>
                        </p>
                      </Col>
                    </Row>

                    <Row justify="center">
                      <Col span={24}>
                        <Timeline style={{ paddingTop: 50, paddingLeft: 20 }}>
                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              <CheckCircleFilled
                                style={{ fontSize: 30, color: "#5DE5A4" }}
                              />
                            }
                          >
                            {paymentTerm.startsWith("TS") ||
                            paymentTerm.startsWith("TP") ||
                            paymentTerm.startsWith("NT") ||
                            paymentTerm.startsWith("DPAV") ||
                            paymentTerm.startsWith("L") ? (
                              <div>
                                <p>Order Confirmed​</p>
                                <h10 style={{ color: "red" }}>
                                  Please remit payment/send Original LC at least
                                  15 Days before requested ETD.​
                                </h10>
                              </div>
                            ) : (
                              <div>
                                <p>Order Confirmed</p>
                                <h10 style={{ color: "red" }}>​</h10>
                              </div>
                            )}
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            }
                          >
                            <p>Payment/Credit Accepted​​</p>
                            <h10 style={{ color: "red" }}>​</h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            }
                          >
                            <p>Booking Confirmed</p>
                            <h10 style={{ color: "red" }}>
                              {" "}
                              Additional charges may apply for any change. ​
                            </h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            }
                          >
                            <p>Cargo Loaded​</p>
                            <h10 style={{ color: "red" }}>​</h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            }
                          >
                            <p>Estimated Time Departure</p>
                            <h10 style={{ color: "red" }}>
                              {" "}
                              Subject to change by Ship Agent without prior
                              notice ​
                            </h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              <ClockCircleOutlined style={{ fontSize: 30 }} />
                            }
                          >
                            <p>Estimated Time Arrival​</p>
                            <h10 style={{ color: "red" }}>
                              {" "}
                              Subject to change by Ship Agent without prior
                              notice​{" "}
                            </h10>
                          </Timeline.Item>
                        </Timeline>
                      </Col>
                    </Row>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default GhostBodyMobile;
