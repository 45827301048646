import React, { Component } from "react";
import { Row, Col, Timeline, Collapse } from "antd";
import {
  ClockCircleOutlined,
  CodepenOutlined,
  PlusOutlined,
  CheckCircleFilled,
  MinusOutlined,
} from "@ant-design/icons";
import { Calculate } from "../Cal";

import "../App.css";
import styles from "../Styles";

const { Panel } = Collapse;
const { format } = require("date-fns");

class BodyMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusColour: "black",
      statusIcon: 0,
    };
  }

  callback(key) {
    console.log(key);
  }

  render() {
    const {
      documentNo,
      refBidNo,
      refPONo,
      bidApproveDate,
      bookingNo,
      ETADate,
      loadDatePlan,
      ETDDate,
      shipAgent,
      shipToParty,
      uniqueRefBidNo,
      uniqueRefPONo,
      searchDropDown,
      soapproveDate,
      transactionType,
      bookingConfirmDate,
      orderDate,
      statusCollapse,
      shipAgentUrl,
      paymentTerm,
      createDate,
    } = this.props;

    var resultStatusTimeline = Calculate(this.props);
    var countResultStatusTimeline = [resultStatusTimeline.length - 1];

    const date = new Date(bidApproveDate);
    var paymentDate =
      "paymentDate " +
      ("0" + (date.getDate() + 15)).slice(-2) +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      date.getFullYear();

    const formatDateETADate = new Date(ETADate);
    const formatDateETDDate = new Date(ETDDate);
    const formatDateBidApproveDate = new Date(bidApproveDate);
    const formatDateLoadDatePlan = new Date(loadDatePlan);
    const formatDateBookingConfirmDate = new Date(bookingConfirmDate);
    const formatOrderDate = new Date(orderDate);
    const formatCreateDate = new Date(createDate);

    return (
      <div>
        {transactionType !== "Z102" ? (
          <Row justify="center">
            <Col span={22}>
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={statusCollapse}
                onChange={this.callback}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
              >
                <Panel
                  style={{ fontFamily: "SCGReg" }}
                  header={"Order Number: " + documentNo}
                  key="1"
                >
                  <div>
                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> PI Number: </p>
                      </Col>

                      <Col span={12}>
                        {searchDropDown === "Option2" ? (
                          <p style={styles.ColumnTitle}>
                            {" "}
                            {uniqueRefBidNo || "-"}{" "}
                          </p>
                        ) : (
                          <p style={styles.ColumnTitle}> {refBidNo || "-"} </p>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> PO Number: </p>
                      </Col>

                      <Col span={12}>
                        {searchDropDown === "Option2" ? (
                          <p style={styles.ColumnTitle}>
                            {" "}
                            {uniqueRefPONo || "-"}{" "}
                          </p>
                        ) : (
                          <p style={styles.ColumnTitle}> {refPONo || "-"} </p>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Order Number: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitleBold}>
                          {" "}
                          {documentNo || "-"}{" "}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Booking Number: </p>
                      </Col>

                      <Col span={12}>
                        {bookingNo === "TRUCK" ? (
                          <p style={styles.ColumnTitle}> - </p>
                        ) : (
                          <p style={styles.ColumnTitle}> {bookingNo || "-"} </p>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> ETD: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> {ETDDate || "-"} </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> ETA: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> {ETADate || "-"} </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Ship Agent: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle} class="ow-break-word">
                          {" "}
                          {shipAgent || "-"}{" "}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}> Ship To: </p>
                      </Col>

                      <Col span={12}>
                        <p style={styles.ColumnTitle}> {shipToParty || "-"} </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <p style={styles.ColumnTitle}>
                          {" "}
                          Shipping Agent Address:{" "}
                        </p>
                      </Col>

                      <Col span={12}>
                        {resultStatusTimeline.indexOf("3") > -1 ? (
                          <p style={styles.ColumnTitle}>
                            {shipAgentUrl === null ||
                            shipAgentUrl === "" ||
                            shipAgentUrl === "-" ? (
                              <p>-</p>
                            ) : (
                              <p class="ow-break-word">
                                <a
                                  href={shipAgentUrl}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {shipAgentUrl}
                                </a>
                              </p>
                            )}
                          </p>
                        ) : (
                          <p style={styles.ColumnTitle}>-</p>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <p style={styles.ColumnGray}>
                        May require Container Number in Bill of Lading for
                        further tracking. (LCL Shipment may be unable to track
                        in Shipping Agents' Website due to system limitation.){" "}
                      </p>
                    </Row>

                    <Row>
                      <Col span={24} style={{ backgroundColor: "#666666" }}>
                        <p style={styles.ColumnTitleBoldWhite}>
                          <CodepenOutlined /> Shipping Summary :{" "}
                          {resultStatusTimeline[countResultStatusTimeline]}
                        </p>
                      </Col>
                    </Row>

                    <Row justify="center">
                      <Col span={24}>
                        <Timeline style={{ paddingTop: 50, paddingLeft: 20 }}>
                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              resultStatusTimeline.indexOf("1") > -1 ? (
                                <CheckCircleFilled
                                  style={{ fontSize: 30, color: "#5DE5A4" }}
                                />
                              ) : (
                                <ClockCircleOutlined style={{ fontSize: 30 }} />
                              )
                            }
                          >
                            <p>
                              {bidApproveDate === null ? (
                                <p>-​​</p>
                              ) : (
                                `${format(formatDateBidApproveDate, "PP")}`
                              )}
                            </p>

                            {resultStatusTimeline.indexOf("2") > -1 ||
                            transactionType === "Z401" ? (
                              <div>
                                <p>Order Confirmed</p>
                              </div>
                            ) : (
                              <div>
                                <p>Order Confirmed​</p>
                                <h10 style={{ color: "red" }}>
                                  Please remit payment/send Original LC at least
                                  15 Days before requested ETD.​​
                                </h10>
                              </div>
                            )}
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              resultStatusTimeline.indexOf("2") > -1 ? (
                                <CheckCircleFilled
                                  style={{ fontSize: 30, color: "#5DE5A4" }}
                                />
                              ) : (
                                <ClockCircleOutlined style={{ fontSize: 30 }} />
                              )
                            }
                          >
                            <p>
                              {soapproveDate === null || createDate === null ? (
                                <p>-​​</p>
                              ) : (
                                `${format(formatCreateDate, "PP")}`
                              )}
                            </p>
                            <p>Payment/Credit Accepted​</p>
                            <h10 style={{ color: "red" }}>​</h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              resultStatusTimeline.indexOf("3") > -1 ? (
                                <CheckCircleFilled
                                  style={{ fontSize: 30, color: "#5DE5A4" }}
                                />
                              ) : (
                                <ClockCircleOutlined style={{ fontSize: 30 }} />
                              )
                            }
                          >
                            <p>
                              {bookingNo === "TRUCK" ||
                              bookingConfirmDate === null ||
                              resultStatusTimeline.indexOf("3") <= -1 ? (
                                <p>​​-</p>
                              ) : (
                                `${format(formatDateBookingConfirmDate, "PP")}`
                              )}
                            </p>
                            <p>Booking Confirmed</p>
                            <h10 style={{ color: "red" }}>
                              Additional charges may apply for any change.​
                            </h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              resultStatusTimeline.indexOf("4") > -1 ? (
                                <CheckCircleFilled
                                  style={{ fontSize: 30, color: "#5DE5A4" }}
                                />
                              ) : (
                                <ClockCircleOutlined style={{ fontSize: 30 }} />
                              )
                            }
                          >
                            <p>
                              {loadDatePlan === null ||
                              resultStatusTimeline.indexOf("3") <= -1 ? (
                                <p>-​​</p>
                              ) : (
                                `${format(formatDateLoadDatePlan, "PP")}`
                              )}
                            </p>
                            <p>Cargo Loaded​</p>
                            <h10 style={{ color: "red" }}>
                              Subject to change by Ship Agent without prior
                              notice,
                            </h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              resultStatusTimeline.indexOf("5") > -1 ? (
                                <CheckCircleFilled
                                  style={{ fontSize: 30, color: "#5DE5A4" }}
                                />
                              ) : (
                                <ClockCircleOutlined style={{ fontSize: 30 }} />
                              )
                            }
                          >
                            <p>
                              {ETDDate === null ||
                              resultStatusTimeline.indexOf("3") <= -1 ? (
                                <p>​​-</p>
                              ) : (
                                `${format(formatDateETDDate, "PP")}`
                              )}
                            </p>
                            <p>Estimated Time Departure</p>
                            <h10 style={{ color: "red" }}>
                              Subject to change by Ship Agent without prior
                              notice
                            </h10>
                          </Timeline.Item>

                          <Timeline.Item
                            style={styles.TimlineTitle}
                            dot={
                              resultStatusTimeline.indexOf("6") > -1 ? (
                                <CheckCircleFilled
                                  style={{ fontSize: 30, color: "#5DE5A4" }}
                                />
                              ) : (
                                <ClockCircleOutlined style={{ fontSize: 30 }} />
                              )
                            }
                          >
                            <p>
                              {ETADate === null ||
                              resultStatusTimeline.indexOf("3") <= -1 ? (
                                <p>​​-</p>
                              ) : (
                                `${format(formatDateETADate, "PP")}`
                              )}
                            </p>
                            <p>Estimated Time Arrival​</p>
                            <h10 style={{ color: "red" }}>
                              Subject to change by Ship Agent without prior
                              notice
                            </h10>
                          </Timeline.Item>
                        </Timeline>
                      </Col>
                    </Row>
                  </div>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default BodyMobile;
