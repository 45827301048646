import React, { Component } from "react";
import { Row, Col, Timeline, Collapse } from "antd";
import {
  ClockCircleOutlined,
  CodepenOutlined,
  MinusOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";

import "../App.css";
import styles from "../Styles";

const { format } = require("date-fns");

const { Panel } = Collapse;

class GhostBody extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      refBidNo,
      refPONo,
      statusCollapse,
      paymentTerm,
      bidApproveDate,
      transactionType,
      shipToParty,
    } = this.props;

    const formatDateBidApproveDate = new Date(bidApproveDate);

    return (
      <div>
        {transactionType === "Z102" ? (
          <Row justify="center">
            <Col span={16}>
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={statusCollapse}
                onChange={this.callback}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
              >
                <Panel header="Pending Shipment" key="1">
                  <div>
                    <Row>
                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> PI Number: </p>
                          </Col>

                          <Col span={12}>
                            <p style={styles.ColumnTitle}>
                              {" "}
                              {!!refBidNo ? refBidNo : "-"}{" "}
                            </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> PO Number: </p>
                          </Col>

                          <Col span={12}>
                            <p style={styles.ColumnTitle}>
                              {" "}
                              {!!refPONo ? refPONo : "-"}{" "}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>
                        <Row>
                          <Col span={12}>
                            <p style={styles.ColumnTitle}> Order Number: </p>
                            <p style={styles.ColumnTitle}> Booking Number: </p>
                            <p style={styles.ColumnTitle}>
                              {" "}
                              Shipping Agent Address:{" "}
                            </p>
                          </Col>

                          <Col span={12}>
                            <p style={styles.ColumnTitle}> - </p>
                            <p style={styles.ColumnTitle}> - </p>
                            <p style={styles.ColumnTitle}> - </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row>
                          <Col span={12}></Col>
                          <Col span={12}></Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={6}>
                        <p style={styles.ColumnTitle}> ETD: </p>
                        <p style={styles.ColumnTitle}> Ship Agent: </p>
                      </Col>

                      <Col span={6}>
                        <p style={styles.ColumnTitle}> - </p>
                        <p style={styles.ColumnTitle}> - </p>
                      </Col>

                      <Col span={6}>
                        <p style={styles.ColumnTitle}> ETA: </p>
                        <p style={styles.ColumnTitle}> Ship To: </p>
                      </Col>

                      <Col span={6}>
                        <p style={styles.ColumnTitle}> - </p>
                        <p style={styles.ColumnTitle}> {shipToParty} </p>
                      </Col>
                    </Row>

                    <Row>
                      <p style={styles.ColumnTitle}>
                        May require Container Number in Bill of Lading for
                        further tracking. (LCL Shipment may be unable to track
                        in Shipping Agents' Website due to system limitation.){" "}
                      </p>
                    </Row>

                    <Row>
                      <Col span={24} style={{ backgroundColor: "#666666" }}>
                        <p
                          class="alignleft"
                          style={styles.ColumnTitleBoldWhite}
                        >
                          <span style={styles.ColumnTitleBoldWhite}>
                            {" "}
                            <CodepenOutlined /> Shipping Summary{" "}
                          </span>
                        </p>
                        <p class="alignright">
                          <span style={styles.ColumnTitleWhite}>
                            Order Status:{" "}
                          </span>
                          <span style={styles.ColumnTitleBoldWhite}>
                            Waiting Payment/Instruction
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </div>

                  <Row justify="center">
                    <Col span={24}>
                      <Timeline mode="left" style={{ paddingTop: 50 }}>
                        <Timeline.Item
                          label={
                            bidApproveDate === null ? (
                              <p></p>
                            ) : (
                              `${format(formatDateBidApproveDate, "PP")}`
                            )
                          }
                          style={styles.TimlineTitle}
                          dot={
                            <CheckCircleFilled
                              style={{ fontSize: 30, color: "green" }}
                            />
                          }
                        >
                          {paymentTerm.startsWith("TS") ||
                          paymentTerm.startsWith("TP") ||
                          paymentTerm.startsWith("NT") ||
                          paymentTerm.startsWith("DPAV") ||
                          paymentTerm.startsWith("L") ? (
                            <div>
                              <p>Order Confirmed​</p>
                              <h10 style={{ color: "red" }}>
                                Please remit payment/send Original LC at least
                                15 Days before requested ETD​.
                              </h10>
                            </div>
                          ) : (
                            <div>
                              <p>Order Confirmed</p>
                              <h10 style={{ color: "red" }}>​</h10>
                            </div>
                          )}
                        </Timeline.Item>

                        <Timeline.Item
                          label=" "
                          style={styles.TimlineTitle}
                          dot={<ClockCircleOutlined style={{ fontSize: 30 }} />}
                        >
                          <p>Payment/Credit Accepted​​</p>
                          <h10 style={{ color: "red" }}>​</h10>
                        </Timeline.Item>

                        <Timeline.Item
                          label=" "
                          style={styles.TimlineTitle}
                          dot={<ClockCircleOutlined style={{ fontSize: 30 }} />}
                        >
                          <p>Booking Confirmed</p>
                          <h10 style={{ color: "red" }}>
                            Additional charges may apply for any change.​
                          </h10>
                        </Timeline.Item>

                        <Timeline.Item
                          label=" "
                          style={styles.TimlineTitle}
                          dot={<ClockCircleOutlined style={{ fontSize: 30 }} />}
                        >
                          <p>Cargo Loaded​</p>
                          <h10 style={{ color: "red" }}>​</h10>
                        </Timeline.Item>

                        <Timeline.Item
                          label=" "
                          style={styles.TimlineTitle}
                          dot={<ClockCircleOutlined style={{ fontSize: 30 }} />}
                        >
                          <p>Estimated Time Departure</p>
                          <h10 style={{ color: "red" }}>
                            {" "}
                            Subject to change by Ship Agent without prior notice
                            ​
                          </h10>
                        </Timeline.Item>

                        <Timeline.Item
                          label=" "
                          style={styles.TimlineTitle}
                          dot={<ClockCircleOutlined style={{ fontSize: 30 }} />}
                        >
                          <p>Estimated Time Arrival​</p>
                          <h10 style={{ color: "red" }}>
                            {" "}
                            Subject to change by Ship Agent without prior notice{" "}
                          </h10>
                        </Timeline.Item>
                      </Timeline>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}
export default GhostBody;
