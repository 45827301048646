import React from  "react";
import { Row } from 'antd';

const Footer = () => {
    return (
        <div>
            <Row justify="end" style = {{paddingTop:100, paddingRight: 20, fontFamily: 'SCGReg'}} >
                <h10 style={{ color: 'black' }}>For further question or any suggestion, please contact SCGC Customer Service.​</h10>
                <h10 style={{ color: 'black' }}></h10>
            </Row>
        </div>
    )
}
export default Footer;